<template>
  <div>
    <a-card>
      <DescriptionList
        title="推送信息"
        size="large"
        style="margin-bottom: 24px;"
      >
        <div style="margin-top: 16px">
          <Description term="推送任务名称"
            ><label>{{ detail.taskName }}</label></Description
          >
          <Description term="推送客户端" style="margin-left:300px"
            ><label>{{
              changeClientSta(detail.clientType)
            }}</label></Description
          >
          <Description term="推送对象" style="display:block;width:100%"
            ><label>{{
              detail.customerType == 1 ? "全部" : "指定用户"
            }}</label></Description
          >
          <div v-if="detail.customerType == 1">
            <Description term="城市" style="display:block;width:100%"
              ><label>{{ changecity(detail.cityIdList) }}</label></Description
            >
            <Description term="消息标题" style="display:block;width:100%"
              ><label>{{ detail.title }}</label></Description
            >
            <Description term="消息内容" style="display:block;width:100%"
              ><label>{{ detail.content }}</label></Description
            >
            <Description term="链接地址" style="display:block;width:100%"
              ><label>{{ detail.link }}</label></Description
            >
          </div>
          <div v-if="detail.customerType == 2">
            <Description term="推送信息" style="display:block;width:100%"
              ><a
                :href="detail.messageFileUrl ? detail.messageFileUrl : null"
                >{{ detail.messageFileUrl ? "推送信息.xlsx" : "" }}</a
              ></Description
            >
            <Description term="推送方式" style="display:block;width:100%"
              ><label>{{
                detail.pushType == 1 ? "立即推送" : "定时推送"
              }}</label></Description
            >
          </div>
        </div>
      </DescriptionList>
    </a-card>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import moment from "moment";
import Bus from "@/bus";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
export default {
  name: "operatePushDetail",
  components: {
    DescriptionList,
    Description
  },

  computed: {
    ...mapGetters("cities", ["userCities"])
  },
  data() {
    return {
      thisDetailId: this.$route.query.thisDetailId,
      detail: [],
      radioGroupList: [
        {
          id: 1,
          name: "全部"
        },
        {
          id: 2,
          name: "指定用户"
        }
      ],
      radioGroupStyle: [
        {
          id: 1,
          name: "立即推送"
        },
        {
          id: 2,
          name: "定时推送"
        }
      ],
      //推送客户端
      pushClient: [
        {
          id: 1,
          name: "客户端"
        },
        {
          id: 2,
          name: "运营端"
        },
        {
          id: 3,
          name: "全部"
        }
      ],
      fileList: [],
      messageFileUrl: ""
    };
  },
  created() {
    this.init();
    this.getUserCities();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/operate/operatePush"
      });
    });
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,
    init() {
      axios({
        url:   this.$baseUrl + "/push/config/query",
        method: "GET",
        params: {
          id: this.thisDetailId
        }
      }).then(res => {
        if (res.data.obj) {
          this.detail = res.data.obj;
        }
      });
    },
    changecity(val) {
      let nameList = [];
      if (val) {
        this.userCities.forEach(item => {
          val.forEach(info => {
            if (item.id == info) {
              nameList.push(item.name);
            }
          });
        });
      }
      return nameList;
    },
    changeClientSta(val) {
      let name = "";
      if (val) {
        this.pushClient.forEach(item => {
          if (item.id == val) {
            name = item.name;
          }
        });
      }
      return name;
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    onChangeStyle(e) {
      this.radioGroupStyleStatus = e.target.value;
      console.log("radio checked", this.radioGroupStyleStatus);
    },
    showMessage(val) {
      return val ? val : undefined;
    }
  }
};
</script>

<style scoped></style>
